<template>
  <v-container>
    <login />
  </v-container>
</template>
<script>
import Login from '@/components/User/Authentication/Login'

export default {
  name: 'Authentication',
  components: {
    Login
  }
}
</script>
