<template>
  <section>
    <v-card class="px-4 login-form">
      <v-card-title>Login</v-card-title>
      <v-card-text>
        <v-form ref="loginForm" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="user.email" :rules="rules.email" :label="$t('email_field')" required outlined />
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="user.password"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="showPassword ? 'text' : 'password'"
                            name="password"
                            :label="$t('password_field')"
                            :hint="$t('min_characters', { number: 8 })"
                            @click:append="showPassword = !showPassword"
                            outlined
              />
            </v-col>
            <v-col>
              <router-link class="reset-password-link" to="reset-password-request">Reset Password</router-link>
            </v-col>
          </v-row>
        </v-form>
        <v-row class="buttons">
          <v-col class="google-icon social-button" cols="12" sm="2" md="2">
            <div class="social-icon">
              <v-btn x-large block color="#E15241" @click="useAuthProvider('google', Google)">
                <v-img src="@/assets/icons/google-logo-mini.svg" />
              </v-btn>
            </div>
          </v-col>
          <v-col class="facebook-icon social-button" cols="12" sm="2" md="2">
            <div class="social-icon">
              <v-btn x-large block color="#4995EC" @click="useAuthProvider('facebook', Facebook)">
                <v-img class="social-icon" src="@/assets/icons/facebook-logo-mini.svg" />
              </v-btn>
            </div>
          </v-col>
          <v-col class="apple-icon social-button" cols="12" sm="2" md="2">
            <div class="social-icon">
              <v-btn x-large block color="#333333" @click="useAuthProvider('apple', Apple)">
                <v-img class="social-icon" src="@/assets/icons/apple-logo-mini.svg" />
              </v-btn>
            </div>
          </v-col>
          <v-col class="binance-icon social-button" cols="12" sm="2" md="2">
            <div class="social-icon">
              <v-btn x-large block color="#F3BA2F" @click="useAuthProvider('binance', Apple)">
                <v-img class="social-icon" src="@/assets/icons/binance-logo-mini.svg" />
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-btn class="login-button" x-large :disabled="!valid" color="#009357" @click="validate">Login</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import { Apple, Facebook, Google } from 'universal-social-auth'

export default {
  name: 'Login',
  data () {
    return {
      responseData: {},
      Apple,
      Facebook,
      Google,
      valid: false,
      user: {
        email: '',
        password: ''
      },
      rules: {
        required: value => !!value || this.$t('required'),
        min: v => (v && v.length >= 8) || this.$t('min_characters', { number: 8 }),
        email: [
          v => !!v || this.$t('required'),
          v => /.+@.+\..+/.test(v) || this.$t('email_must_be_valid')
        ]
      },
      showPassword: false
    }
  },
  methods: {
    ...mapActions({
      login: 'user/login',
      socialLogin: 'user/socialLogin'
    }),
    validate () {
      this.login(this.user)
        .then(() => {
          this.$router.push('/me/office')
        })
    },
    useAuthProvider (provider, proData) {
      this.$Oauth.authenticate(provider, proData)
        .then(response => {
          if (response.code) {
            this.responseData.code = response.code
            this.responseData.provider = provider
            this.useSocialLogin()
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    useSocialLogin () {
      this.socialLogin({
        provider: this.responseData.provider,
        code: this.responseData.code
      })
        .then(() => {
          this.$router.push('/me/office')
        })
        .catch(err => {
          console.error(err)
        })
    }
  }
}
</script>
<style>
.login-form {
  border-radius: 15px;
  width: 70%;
  margin: auto;
}

.login-form .v-card__title {
  justify-content: center;
  font-size: 32px;
  line-height: 100%;
  color: #649187;
}

.login-form .v-card__text {
  width: 80%;
  margin: auto;
}

.reset-password-link {
  font-size: 14px;
  font-weight: 400;
}

.social-icon {
  max-width: 70px;
}

.login-button {
  color: #FFFFFF!important;
  font-weight: 600;
  width: 100%;
}

@media screen and (max-width: 428px) {
  .login-form {
    width: 100%;
  }

  .login-form .v-card__text {
    width: 100%;
    margin: auto;
  }

  .social-button {
    flex: 0 0 24%;
    max-width: 24%;
  }

  .google-icon {
    order: 2;
  }

  .facebook-icon {
    order: 3;
  }

  .apple-icon {
    order: 4;
  }

  .binance-icon {
    order: 5;
  }
}
</style>
